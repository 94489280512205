// Imports => React
import React, { useEffect, useState, useMemo } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

// Imports => Constants
import { KEYS, THEMES, TYPES, VARIANTS } from '@constants';

// Imports => Utilities
import { AcIsSet } from '@utils';

// Imports => Hooks
import { useFormActions } from '@hooks';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcTextInput from '@atoms/ac-text-input/ac-text-input.web';
import AcSelectBox from '@atoms/ac-select-box/ac-select-box.web';
import AcButton from '@atoms/ac-button/ac-button.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';

const _CLASSES = {
  MAIN: 'ac-edit-hammer-type-modal',
  CONTENT: 'ac-edit-hammer-type-modal__content',
};

const AcEditHammerTypeModal = ({
  store: { ui, hammer_types, jeanlutz },
  data,
  submit,
}) => {
  const [fields, setFields] = useState({
    name: data.type,
    type: data.type,
    series: data.series,
    description: data.description,
    jean_lutz_hammer_type: data.jean_lutz_hammer_type,
    article_number: data.article_number,
  });
  const [errors, setErrors] = useState({
    type: null,
    series: null,
    description: null,
    jean_lutz_hammer_type: AcIsSet(data.jean_lutz_hammer_type)
      ? null
      : undefined,
    article_number: null,
  });
  const { hasErrors, handleInputChange, handleInputValidation } =
    useFormActions({
      fields,
      setFields,
      errors,
      setErrors,
    });

  useEffect(() => {
    jeanlutz.list_hammers({ options: true });
  }, []);

  const handleCancel = async (event) => {
    if (event && event.preventDefault) event.preventDefault();
    await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
  };

  const handleSubmit = (event) => {
    if (event && event.preventDefault) event.preventDefault();
    if (event && event.persist) event.persist();

    if (submit)
      submit(data.id, { ...fields, name: fields.type }).then(() =>
        ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false)
      );
  };

  const getContentClassNames = useMemo(() => {
    return clsx([_CLASSES.CONTENT]);
  }, []);

  const getStyleClassNames = useMemo(() => {
    return clsx([_CLASSES.MAIN]);
  }, []);

  const getTypeInputOptions = useMemo(() => {
    return {
      type: TYPES.TEXT,
      label: 'Type',
      name: 'type',
      value: fields.type,
      callback: handleInputChange,
      validation: handleInputValidation,
      focus: true,
    };
  }, [fields, fields.type]);

  const getSeriesInputOptions = useMemo(() => {
    return {
      type: TYPES.TEXT,
      label: 'Series',
      name: 'series',
      value: fields.series,
      callback: handleInputChange,
      validation: handleInputValidation,
    };
  }, [fields, fields.series]);

  const getArticleNumberInputOptions = useMemo(() => {
    return {
      type: TYPES.TEXT,
      label: 'Article number',
      name: 'article_number',
      value: fields.article_number,
      callback: handleInputChange,
      validation: handleInputValidation,
      required: true,
    };
  }, [fields, fields.article_number]);

  const getDescriptionInputOptions = useMemo(() => {
    return {
      type: TYPES.TEXT,
      label: 'Description',
      name: 'description',
      value: fields.description,
      callback: handleInputChange,
      validation: handleInputValidation,
      required: false,
    };
  }, [fields, fields.description]);

  const getExternalTypeReferenceSelectOptions = useMemo(() => {
    const { current_jean_lutz_hammers } = jeanlutz;
    let options = [];
    let selectedJlItem = {};
    if (current_jean_lutz_hammers) {
      const len = current_jean_lutz_hammers.length;
      let n = 0;

      for (n; n < len; n++) {
        const item = current_jean_lutz_hammers[n];

        const object = {
          name: item.name,
          value: item.id,
        };

        if (item.name === fields.jean_lutz_hammer_type) {
          selectedJlItem = item;
          setFields((flds) => ({ ...flds, jean_lutz_hammer_type: item }));
        }

        options.push(object);
      }
    }

    return {
      type: TYPES.TEXT,
      label: 'Sensor configuration',
      name: 'jean_lutz_hammer_type',
      placeholder: 'Select a sensor configuration',
      value: fields.jean_lutz_hammer_type.id,
      callback: handleInputChange,
      validation: handleInputValidation,
      maxOptions: 7,
      options,
    };
  }, [
    jeanlutz.current_jean_lutz_hammers,
    fields,
    fields.jean_lutz_hammer_type,
  ]);

  const getCancelButtonOptions = useMemo(() => {
    return {
      type: TYPES.BUTTON,
      theme: THEMES.OMEGA,
      variant: VARIANTS.TEXT,
      title: 'Cancel',
      callback: handleCancel,
    };
  });

  const getSubmitButtonOptions = useMemo(() => {
    return {
      type: TYPES.SUBMIT,
      theme: THEMES.ALPHA,
      disabled: hasErrors,
      title: 'Save changes',
      callback: handleSubmit,
    };
  }, [fields, errors, hasErrors]);

  return (
    <div className={getStyleClassNames}>
      <div className={getContentClassNames}>
        <form method={'post'} onSubmit={handleSubmit}>
          <AcContainer fluid>
            <AcRow>
              <AcColumn>
                <AcTextInput {...getTypeInputOptions} />
              </AcColumn>
            </AcRow>

            <AcRow>
              <AcColumn>
                <AcTextInput {...getSeriesInputOptions} />
              </AcColumn>
            </AcRow>

            <AcRow>
              <AcColumn>
                <AcTextInput {...getArticleNumberInputOptions} />
              </AcColumn>
            </AcRow>

            <AcRow>
              <AcColumn>
                <AcTextInput {...getDescriptionInputOptions} />
              </AcColumn>
            </AcRow>

            <AcRow>
              <AcColumn>
                <AcSelectBox
                  {...getExternalTypeReferenceSelectOptions}
                  disabled
                />
              </AcColumn>
            </AcRow>

            <AcRow className={'h-margin-top-20'}>
              <AcColumn
                xxs={12}
                xs={7}
                sm={6}
                className={'h-text--align-left h-flex-v-align-center'}
              >
                <AcButton {...getCancelButtonOptions}>
                  <span>Cancel</span>
                </AcButton>
              </AcColumn>

              <AcColumn
                xxs={12}
                xs={5}
                sm={6}
                className={'h-text--align-right'}
              >
                <AcButton {...getSubmitButtonOptions}>
                  <span>Save changes</span>
                </AcButton>
              </AcColumn>
            </AcRow>
          </AcContainer>
        </form>
      </div>

      {hammer_types.is_busy && <AcLoader loading={true} cover />}
    </div>
  );
};

export default withStore(observer(AcEditHammerTypeModal));
