// Imports => React
import React, { useEffect, useState, useMemo } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

// Imports => Constants
import { KEYS, THEMES, TYPES, VARIANTS } from '@constants';

// Imports => Utilities
import { AcIsSet } from '@utils';

// Imports => Hooks
import { useFormActions } from '@hooks';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcTextInput from '@atoms/ac-text-input/ac-text-input.web';
import AcButton from '@atoms/ac-button/ac-button.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';

const _CLASSES = {
	MAIN: 'ac-edit-powerpack-type-modal',
	CONTENT: 'ac-edit-powerpack-type-modal__content',
};

const AcEditPowerpackTypeModal = ({
	store: { ui, powerpack_types },
	data,
	submit,
}) => {
	const [fields, setFields] = useState({
		name: data.type,
		type: data.type,
		series: data.series,
		description: data.description,
		jean_lutz_power_pack_type: data.jean_lutz_power_pack_type,
		compatible_cu_series: data.compatible_cu_series,
		article_number: data.article_number,
	});
	const [errors, setErrors] = useState({
		type: null,
		series: null,
		description: null,
		jean_lutz_power_pack_type: AcIsSet(data.jean_lutz_power_pack_type)
			? null
			: undefined,
		compatible_cu_series: AcIsSet(data.compatible_cu_series) ? null : undefined,
		article_number: null,
	});
	const { hasErrors, handleInputChange, handleInputValidation } =
		useFormActions({
			fields,
			setFields,
			errors,
			setErrors,
		});

	const handleCancel = async (event) => {
		if (event && event.preventDefault) event.preventDefault();
		await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
	};

	const handleSubmit = (event) => {
		if (event && event.preventDefault) event.preventDefault();
		if (event && event.persist) event.persist();

		if (submit)
			submit(data.id, { ...fields, name: fields.type }).then(() =>
				ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false)
			);
	};

	const getContentClassNames = useMemo(() => {
		return clsx([_CLASSES.CONTENT]);
	}, []);

	const getStyleClassNames = useMemo(() => {
		return clsx([_CLASSES.MAIN]);
	}, []);

	const getTypeInputOptions = useMemo(() => {
		return {
			type: TYPES.TEXT,
			label: 'Type',
			name: 'type',
			value: fields.type,
			callback: handleInputChange,
			validation: handleInputValidation,
			focus: true,
		};
	}, [fields, fields.type]);

	const getSeriesInputOptions = useMemo(() => {
		return {
			type: TYPES.TEXT,
			label: 'Series',
			name: 'series',
			value: fields.series,
			callback: handleInputChange,
			validation: handleInputValidation,
		};
	}, [fields, fields.series]);

	const getExternalTypeReferenceSelectOptions = useMemo(() => {
		return {
			type: TYPES.TEXT,
			label: 'Interface',
			name: 'jean_lutz_power_pack_type',
			placeholder: 'Select a type',
			value: fields.jean_lutz_power_pack_type,
			disabled: true,
		};
	}, [fields, fields.jean_lutz_power_pack_type]);

	const getControlUnitSelectOptions = useMemo(() => {
		return {
			type: TYPES.TEXT,
			label: 'Compatible control unit series',
			name: 'compatible_cu_series',
			placeholder: 'Select a control unit series',
			value: fields.compatible_cu_series,
			disabled: true,
		};
	}, [fields, fields.compatible_cu_series]);

	const getArticleNumberInputOptions = useMemo(() => {
		return {
			type: TYPES.TEXT,
			label: 'Article number',
			name: 'article_number',
			value: fields.article_number,
			callback: handleInputChange,
			validation: handleInputValidation,
			required: true,
		};
	}, [fields, fields.article_number]);

	const getDescriptionInputOptions = useMemo(() => {
		return {
			type: TYPES.TEXT,
			label: 'Description',
			name: 'description',
			value: fields.description,
			callback: handleInputChange,
			validation: handleInputValidation,
			required: false,
		};
	}, [fields, fields.description]);

	const getCancelButtonOptions = useMemo(() => {
		return {
			type: TYPES.BUTTON,
			theme: THEMES.OMEGA,
			variant: VARIANTS.TEXT,
			title: 'Cancel',
			callback: handleCancel,
		};
	});

	const getSubmitButtonOptions = useMemo(() => {
		return {
			type: TYPES.SUBMIT,
			theme: THEMES.ALPHA,
			disabled: hasErrors,
			title: 'Save changes',
			callback: handleSubmit,
		};
	}, [fields, errors, hasErrors]);

	return (
		<div className={getStyleClassNames}>
			<div className={getContentClassNames}>
				<form method={'post'} onSubmit={handleSubmit}>
					<AcContainer fluid>
						<AcRow>
							<AcColumn>
								<AcTextInput {...getTypeInputOptions} />
							</AcColumn>
						</AcRow>

						<AcRow>
							<AcColumn>
								<AcTextInput {...getSeriesInputOptions} />
							</AcColumn>
						</AcRow>

						<AcRow>
							<AcColumn>
								<AcTextInput {...getControlUnitSelectOptions} />
							</AcColumn>
						</AcRow>

						<AcRow>
							<AcColumn>
								<AcTextInput {...getArticleNumberInputOptions} />
							</AcColumn>
						</AcRow>

						<AcRow>
							<AcColumn>
								<AcTextInput {...getDescriptionInputOptions} />
							</AcColumn>
						</AcRow>

						<AcRow>
							<AcColumn>
								<AcTextInput {...getExternalTypeReferenceSelectOptions} />
							</AcColumn>
						</AcRow>

						<AcRow className={'h-margin-top-20'}>
							<AcColumn
								xxs={12}
								xs={7}
								sm={6}
								className={'h-text--align-left h-flex-v-align-center'}
							>
								<AcButton {...getCancelButtonOptions}>
									<span>Cancel</span>
								</AcButton>
							</AcColumn>

							<AcColumn
								xxs={12}
								xs={5}
								sm={6}
								className={'h-text--align-right'}
							>
								<AcButton {...getSubmitButtonOptions}>
									<span>Save changes</span>
								</AcButton>
							</AcColumn>
						</AcRow>
					</AcContainer>
				</form>
			</div>

			{powerpack_types.is_busy && <AcLoader loading={true} cover />}
		</div>
	);
};

export default withStore(observer(AcEditPowerpackTypeModal));
